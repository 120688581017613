import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Footer from 'components/Footer';
import Header from 'components/Header';
import YouTubeLazyLoaded from 'components/YouTubeLazyLoaded';

import { getYoutubeId, stripHTML } from '../../utils/functions';
import { RecipeProps } from './models';

import './Recipe.scss';

const Recipe: FC<{ data: RecipeProps }> = ({
  data: {
    recipe: {
      pageLogo,
      principalTitle,
      instructions,
      youtube,
      bannerDesktop,
      bannerMobile,
      urls,
      seo,
      imageseo,
    },
    allRecipe: { nodes },
    recipeList: { seeMore, relatedRecipes, activate },
    footer,
    header,
    siteSettings,
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  let videoId;

  if (youtube) {
    videoId = getYoutubeId(youtube);
  }

  return (
    <Layout headerTransparent alterLogo={pageLogo || null}>
      {header && <Header data={header} settings={siteSettings} />}
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="Recipe"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
        imageurl={imageseo.childImageSharp.original.src}
      />
      <div className="mx-auto section-container recipe">
        <div className="container-fluid px-0">
          <div className="row recipe__banner mx-0">
            <div className="col px-0">
              <Image
                imageData={bannerDesktop}
                alt={bannerDesktop.altText}
                className="d-none d-md-block"
              />
              <Image
                imageData={bannerMobile}
                alt={bannerMobile.altText}
                className="d-block d-md-none"
              />
            </div>
          </div>
          <div className="row mx-0">
            <div className="col px-0">
              <div className="recipe__text pb-3">
                <section className="px-3">
                  <DangerouslySetInnerHtml
                    html={principalTitle}
                    element="h1"
                    className="mb-0 text-uppercase pt-4"
                  />
                  <DangerouslySetInnerHtml html={instructions} element="div" className="pt-2" />
                  <div className="pt-3">
                    <DangerouslySetInnerHtml
                      html={activate}
                      element="div"
                      className="pt-2 recipe__related-item-text"
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="row recipe__video mx-0">
            <div className="col px-0">
              {videoId && <YouTubeLazyLoaded videoId={videoId} videoPoster={bannerDesktop} />}
            </div>
          </div>
          <div className="row mx-0">
            <div className="col px-0">
              <div className="recipe__related pt-3">
                <h4 className="py-2 ms-4 ps-4">{relatedRecipes}</h4>
                <div className="container-fluid recipe__related-wrapper py-4">
                  <div className="row">
                    {nodes.map(
                      ({
                        id,
                        title: name,
                        image: imageRecipes,
                        instructions: instruction,
                        url,
                      }) => (
                        <div className="col-12 col-md-4" key={id}>
                          <div className="mx-auto recipe__related-item mb-4 mb-md-0">
                            <div className="recipe__related-item-image pb-3">
                              <Image imageData={imageRecipes} alt={imageRecipes.altText} />
                              <i className="far fa-play-circle" />
                            </div>
                            <div className="px-3 pb-3">
                              <h3 className="py-2 text-center">{name}</h3>
                              <DangerouslySetInnerHtml
                                html={stripHTML(instruction).substring(0, 70)}
                                element="div"
                                className="pt-2 recipe__related-item-text"
                              />
                              <div className="text-center pt-3">
                                <Link to={url} className="btn btn-primary">
                                  {seeMore}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {footer ? <Footer data={footer} /> : <></>}
    </Layout>
  );
};

export const query = graphql`
  query ($url: String, $lang: String) {
    recipe(url: { eq: $url }) {
      pageLogo {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      principalTitle
      title
      instructions
      youtube
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      imageseo: image {
        childImageSharp {
          original {
            src
          }
        }
      }
      bannerDesktop {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      bannerMobile {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
    }
    recipeList {
      seeMore
      relatedRecipes
      activate
    }
    allRecipe(limit: 3) {
      nodes {
        id
        title
        principalTitle
        instructions
        url
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    header(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 200)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 70)
          }
        }
      }
      socialMedia {
        name
        url
        queryString
      }
      navs {
        name
        url
        queryString
      }
      sidebar {
        url {
          url
        }
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 60)
            }
          }
        }
        url {
          url
        }
      }
    }
    siteSettings {
      siteName
      defaultLang
      popupLanguage
      avaliableLanguages {
        url
        code
        title
        longTitle
      }
    }
    footer(lang: { eq: $lang }) {
      logo {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 150)
          }
        }
      }
      extraLogo {
        childImageSharp {
          gatsbyImageData(quality: 100)
        }
      }
      navs {
        name
        url
      }
      contact
      copyright
      socialMedia {
        iconImage {
          url
          altText
          fallbackUrl
        }
        iconLink {
          url
          name
          icon
        }
      }
    }
  }
`;

export default Recipe;
